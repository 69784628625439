
  import { UserInfoInterface, Role } from '@/interface/BaseInterface'
  import { Vue, Component } from 'vue-property-decorator'
  import MemberApi from '../api/memberApi'
  import { assign } from 'lodash'
  import getApi from '@/api'

  const memberApi: MemberApi = getApi('member')

  @Component
  export default class CretateMember extends Vue {
    data: UserInfoInterface = {
      _id: '',
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      phoneNumber: ''
    }

    roles: Role[] = []

    isEdit = false

    reset() {
      this.isEdit = false
      this.data = {
        _id: '',
        username: '',
        email: '',
        firstName: '',
        lastName: '',
        phoneNumber: ''
      }
    }
    handleOpen() {
      if (this.isEdit) {
        return
      }
      this.reset()
    }

    handleClose() {
      this.reset()
    }

    setData(data: UserInfoInterface) {
      this.reset()
      this.isEdit = true
      this.data = data
    }

    async handleSubmit() {
      // @ts-ignore
      this.$refs.formActive.validate(async (value: boolean) => {
        if (value) {
          const result = assign(this.data)
          let data: UserInfoInterface = {
            _id: '',
            username: '',
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: ''
          }
          try {
            if (this.isEdit) {
              data = await memberApi.update(this.data._id, result)
            } else {
              data = await memberApi.create(result)
            }
            this.$emit('submit', data)
            this.$message.success(this.isEdit ? (this.$t('editSuccess') as string) : (this.$t('createSuccess') as string))
          } catch (error) {
            const errors = error?.response?.data?.errors || []
            if (errors?.length > 0) {
              if (errors[0].property === 'email') {
                this.$message.error(`Email '${this.data.email}' already exists.`)
              } else {
                this.$message.error(`Username '${this.data.username}' already exists.`)
              }
            }
          }
        }
      })
    }

    async getRole() {
      try {
        this.roles = await memberApi.getRoles('system')
      } catch (error) {
        console.log(error)
      }
    }

    created() {
      this.getRole()
    }
  }
