
  import { Component, Vue } from 'vue-property-decorator'
  import { UserInfoInterface, MetaInterface } from '@/interface/BaseInterface'
  import getApi from '@/api'
  import CreateMember from '../components/CreateMember.vue'
  import { namespace } from 'vuex-class'
  import { SPopupBase } from '@/store/base'
  import MemberApi from '../api/memberApi'

  const memberApi: MemberApi = getApi('member')
  const tBase = namespace('base')
  @Component({
    components: { CreateMember }
  })
  export default class Member extends Vue {
    @tBase.Action('setOpenPopup') setOpenPopup!: (data: SPopupBase) => void

    data: UserInfoInterface[] = []
    meta: MetaInterface = {
      page: 1,
      perPage: 10,
      total: 100
    }
    isLoading = false
    filter = {
      searchText: ''
    }

    async init() {
      try {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        const result = await memberApi.all({ page: this.meta.page, perPage: this.meta.perPage, searchText: this.filter.searchText })
        this.data = result.items
        this.meta.page = result.meta.page
        this.meta.total = result.meta.total
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        this.$message.error(`Account or email address already exists.`)
      }
    }

    handleFilter(filter: any) {
      this.filter.searchText = filter.search
      this.meta = {
        page: 1,
        perPage: 10,
        total: 100
      }
      this.init()
    }

    handleSizeChange(size: number) {
      this.meta.perPage = size
      this.meta.page = 1
      this.init()
    }

    handlePageChange(page: number) {
      this.meta.page = page
      this.init()
    }

    handleSubmit() {
      this.meta = {
        page: 1,
        perPage: 10,
        total: 100
      }
      this.filter = {
        searchText: ''
      }
      this.init()
    }

    handleRemove(value: UserInfoInterface) {
      console.log('remove', value)
    }

    handleEdit(value: UserInfoInterface) {
      console.log('edit', value)
      this.setOpenPopup({
        module: 'createMember',
        isOpen: true
      })
      if (this.$refs.memberPopup) {
        //@ts-ignore
        this.$refs.memberPopup.setData(value)
      }
    }

    handleResetPassword(value: UserInfoInterface) {
      this.$confirm(`Do you want to reset ${value.lastName}'s password?`, 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await memberApi.resetPassword(value._id)
          this.$message({
            type: 'success',
            message: 'Reset completed'
          })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Canceled'
          })
        })
    }

    handleAddMember() {
      this.setOpenPopup({
        module: 'createMember',
        isOpen: true
      })
    }

    created() {
      this.init()
    }
  }
